@import url('https://fonts.googleapis.com/css2?family=Carter+One&display=swap');

.details-container {
  height: 100vh;
  color: #111;
  background-color: #111;
}

.details-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: white;
}

.details-stats {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #111;
  font-family: 'Roboto', sans-serif;
  padding: 3rem;
}

.UN-member {
  width: 75px;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.flag,
.coatOfArms {
  max-width: 200px;
  height: auto;
  margin: 1rem;
}

.header3 {
  background-color: white;
}

.details-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3rem;
  padding: 1rem;
  background-color: white;
}

@media screen and (max-width: 768px) {
  .flag,
  .coatOfArms {
    max-width: 100px;
  }

  .details-stats {
    display: flex;
    flex-direction: column;
    padding: 2rem;

    h4 {
      font-size: 1rem;
    }
  }
}
