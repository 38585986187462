.countries-container {
  max-width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: black;
}

.fixed {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100vw;
}

@media screen and (max-width: 768px) {
  .countries-container {
    display: grid;
    grid-template-columns: 1fr;
  }
}
