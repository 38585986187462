nav {
  background-color: #dedede;
}

.nav-title {
  text-decoration: none;
  color: #444;
  font-size: 1.5rem;
  font-weight: 700;

  &:hover {
    color: #000;
  }
}
