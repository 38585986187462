@import './animate.scss';

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  text-align: center;
  height: 200px;
}

.arrow {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.flag {
  max-width: 150px;
  max-height: 150px;
}

@media screen and (max-width: 768px) {
  .card {
    width: 100vw;
  }
}
