.loader-container {
  position: fixed;
  top: 3rem;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.loader {
  border: 1rem solid #333;
  border-top: 1rem solid white;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  animation: spin 0.75s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
